<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover">
      <div class="addbutton">
        <el-button type="primary"
                   size="small"
                   icon="el-icon-plus"
                   @click="dialogFormVisible = true">新增用户</el-button>
      </div>
      <el-table :data="userdata.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                border
                :header-cell-style="{background:'#f2f5f9',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 180px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading">
        <el-table-column label="姓名"
                         prop="name"
                         align="center">
        </el-table-column>
        <el-table-column label="手机号"
                         prop="mobile"
                         align="center">
        </el-table-column>
        <el-table-column label="部门"
                         prop="deptname"
                         align="center"
                         :filters="[{ text: '运营', value: '运营' }, { text: '创融', value: '创融' }, { text: '财务', value: '财务' }, { text: '市场', value: '市场' }, { text: '电商', value: '电商' }]"
                         :filter-method="deptnamefilterTags">
        </el-table-column>
        <el-table-column label="角色"
                         prop="rulename"
                         align="center"
                         :filters="[{ text: '超级管理员', value: '超级管理员' }, { text: '普通管理员', value: '普通管理员' }, { text: '财务管理员', value: '财务管理员' }]"
                         :filter-method="rulenamefilterTags">
          <template slot-scope="scope">
            <span class="green_font"
                  v-if="scope.row.ruleid==1">{{scope.row.rulename}}</span>
            <span v-else>{{scope.row.rulename}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态"
                         prop="status"
                         align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status==1">启用</span>
            <span v-else
                  class="red">停用</span>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot="header">
            <el-input v-model="search"
                      size="mini"
                      placeholder="输入关键字搜索" />
          </template>
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="新增用户"
                 :visible.sync="dialogFormVisible"
                 append-to-body>
        <el-form ref="userinfo-form"
                 :model="userinfo"
                 label-width="120px"
                 :rules="formRules">
          <el-form-item label="姓名"
                        prop="name">
            <el-input v-model="userinfo.name"></el-input>
          </el-form-item>
          <el-form-item label="手机"
                        prop="mobile">
            <el-input v-model="userinfo.mobile"></el-input>
          </el-form-item>
          <el-form-item label="密码"
                        prop="password">
            <el-input v-model="userinfo.password"
                      show-password></el-input>
          </el-form-item>
          <el-form-item label="部门"
                        prop="deptid">
            <el-select v-model="userinfo.deptid"
                       placeholder="请选择部门"
                       multiple
                       filterable
                       remote
                       reserve-keyword>
              <el-option v-for="item in deptoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户"
                        prop="username">
            <el-select v-model="userinfo.userid"
                       multiple
                       filterable
                       remote
                       reserve-keyword
                       placeholder="请选择客户"
                       :loading="loading">
              <el-option v-for="item in customeroptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色"
                        prop="ruleid">
            <el-select v-model="userinfo.ruleid"
                       placeholder="请选择角色"
                       clearable
                       filterable>
              <el-option v-for="item in ruleoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateUser">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="修改用户"
                 :visible.sync="dialogFormVisibles"
                 append-to-body
                 destroy-on-close>
        <el-form ref="userinfos-form"
                 :model="userinfos"
                 label-width="120px"
                 :rules="formRuless">
          <el-form-item label="姓名"
                        prop="name">
            <el-input v-model="userinfos.name"></el-input>
          </el-form-item>
          <el-form-item label="手机"
                        prop="mobile">
            <el-input v-model="userinfos.mobile"></el-input>
          </el-form-item>
          <el-form-item label="密码"
                        prop="password">
            <el-input v-model="userinfos.password"
                      show-password></el-input>
          </el-form-item>
          <el-form-item label="部门"
                        prop="deptid">
            <el-select v-model="userinfos.deptid"
                       multiple
                       filterable
                       remote
                       reserve-keyword
                       placeholder="请选择部门">
              <el-option v-for="item in deptoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色"
                        prop="ruleid">
            <el-select v-model="userinfos.ruleid"
                       placeholder="请选择角色"
                       clearable
                       filterable>
              <el-option v-for="item in ruleoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态"
                        prop="ruleid">
            <el-select v-model="userinfos.status"
                       placeholder="请选择状态"
                       clearable
                       filterable>
              <el-option v-for="item in statusoptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       :loading="updateProfileLoading"
                       @click="onUpdateUsers">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getDepartmentList, getRoleList } from '@/api/global'
import { addNewUser, getUserList, getDellUser, getEditUser, upDataUser } from '@/api/user'
import globalBus from '@/utils/global-bus'

export default {
  name: 'UserIndex',
  components: {},
  props: {},
  data () {
    return {
      loading: true,
      userinfo: {
        password: '',
        mobile: '',
        name: '',
        headimgurl: '',
        email: '',
        deptid: '',
        ruleid: '',
        status: ''
      }, // 用户资料
      headimgurl: '',
      dialogVisible: false, // 控制上传图片裁切预览的显示状态
      previewImage: '', // 预览图片
      cropper: null, // 裁切器示例
      updatePhotoLoading: false, // 更新用户头像 loading 状态
      updateProfileLoading: false, // 更新基本信息的 loading 状态
      formRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,14}$/, message: '密码由数字+字母形式组合', trigger: 'blur' }

        ],
        deptid: [{ required: true, message: '请输入选择部门', trigger: 'blur' }],
        ruleid: [{ required: true, message: '请输入选择角色', trigger: 'blur' }]
      },
      userdata: [],
      search: '',
      dialogFormVisible: false,
      dialogFormVisibles: false,
      userinfos: {
        id: '',
        password: '',
        mobile: '',
        name: '',
        deptid: '',
        userid: '',
        ruleid: '',
        status: ''
      },
      formRuless: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
        ],
        deptid: [{ required: true, message: '请输入选择部门', trigger: 'blur' }],
        ruleid: [{ required: true, message: '请输入选择角色', trigger: 'blur' }]
      },
      deptoptions: [],
      ruleoptions: [],
      statusoptions: [{
        id: 1,
        name: '启用'
      }, {
        id: 2,
        name: '停用'
      }]

    }
  },
  mounted () {
    this.loadnav()
    this.loaddept()
    this.loadRoleList()
    this.loaduser()
  },
  methods: {
    loadnav () {
      //  发布通知
      if (this.$route.name === 'user') {
        this.topname = '权限管理'
        this.navnames = '用户管理'
      }
      globalBus.$emit('navinfo', { status: 1, topname: this.topname, navname: this.navnames })
    },
    loaddept () {
      getDepartmentList().then(res => {
        if (res.data.status === 'success') {
          this.deptoptions = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadRoleList () {
      getRoleList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.ruleoptions = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    loaduser () {
      getUserList().then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.userdata = res.data.data
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    onUpdateUser () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['userinfo-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.sendinfo()
      })
    },
    sendinfo () {
      this.updatePhotoLoading = true
      addNewUser(this.userinfo).then(res => {
        if (res.data.status === 'success') {
          this.dialogFormVisible = false
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loaduser()
          this.updatePhotoLoading = false
        } else {
          this.$message.error(res.data.message)
          this.updatePhotoLoading = false
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onUpdateUsers () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['userinfos-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.updatePhotoLoading = true
        upDataUser(this.userinfos).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisibles = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loaduser()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      })
    },
    handleEdit (row) {
      getEditUser({ id: row.id }).then(res => {
        this.dialogFormVisibles = true
        this.userinfos.name = res.data.data.name
        this.userinfos.mobile = res.data.data.mobile
        this.userinfos.deptid = res.data.deptid
        this.userinfos.ruleid = res.data.data.ruleid
        this.userinfos.status = res.data.data.status
        this.userinfos.id = row.id
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDellUser({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.dialogFormVisible = false
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loaduser()
            this.updatePhotoLoading = false
          } else {
            this.$message.error(res.data.message)
            this.updatePhotoLoading = false
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    deptnamefilterTags (value, row) {
      return row.deptname === value
    },
    rulenamefilterTags (value, row) {
      return row.rulename === value
    }
  }
}
</script>

<style scoped lang="less">
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
/deep/.el-table .green_font {
  color: #10aa10;
  font-weight: 700;
}
.red {
  color: red;
}
</style>
