import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/'
import Home from '@/views/home/'
import Layout from '@/views/layout/'
import Setting from '@/views/setting/'
import Department from '@/views/manage/department'
import User from '@/views/manage/user'
import Role from '@/views/manage/role'
import Classify from '@/views/column/classify'
import Classvideo from '@/views/column/classvideo'
import Ad from '@/views/ad/'
import Setup from '@/views/setup/'
import Nav from '@/views/nav/'
import Video from '@/views/video/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '', // path 为空，会作为默认子路由渲染
        name: 'home',
        component: Home
      },
      {
        path: '/setting',
        name: 'setting',
        component: Setting
      },
      {
        path: '/department',
        name: 'department',
        component: Department
      },
      {
        path: '/user',
        name: 'user',
        component: User
      },
      {
        path: '/role',
        name: 'role',
        component: Role
      },
      {
        path: '/classify',
        name: 'classify',
        component: Classify
      },
      {
        path: '/ad',
        name: 'ad',
        component: Ad
      },
      {
        path: '/setup',
        name: 'setup',
        component: Setup
      },
      {
        path: '/nav',
        name: 'nav',
        component: Nav
      },
      {
        path: '/video',
        name: 'video',
        component: Video
      },
      {
        path: '/classvideo',
        name: 'classvideo',
        component: Classvideo
      }
    ]
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const user = JSON.parse(window.localStorage.getItem('user'))
  if (
    to.path !== '/login' &&
    to.path !== '/register' &&
    to.path !== '/findpassword'
  ) {
    if (user) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
