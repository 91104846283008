<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover">
      <el-form ref="form"
               :model="form"
               label-width="100px">
        <el-form-item label="网站名称">
          <el-input v-model="form.webtitle"></el-input>
        </el-form-item>
        <el-form-item label="所有版权">
          <el-input type="textarea"
                    v-model="form.copyright"></el-input>
        </el-form-item>
        <el-form-item label="备案号">
          <el-input v-model="form.beian"></el-input>
        </el-form-item>
        <el-form-item label="备案链接">
          <el-input v-model="form.beianurl"></el-input>
        </el-form-item>
       <!-- <el-form-item label="视频地址">
          <el-input v-model="form.vurl"></el-input>
        </el-form-item>
        <el-form-item label="视频缩略图">
          <el-upload class="avatar-uploader"
                     action="https://mpapi.ghtechwx.com/api/upImg"
                     :headers="myHeaders"
                     :show-file-list="false"
                     :on-success="handleVpicSuccess"
                     :before-upload="beforeVpicUpload">
            <img v-if="form.vpic"
                 :src="form.vpic"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>-->
        <el-form-item label="微信分享链接">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="微信分享略图">
          <el-upload class="avatar-uploader"
                     action="https://mpapi.ghtechwx.com/api/upImg"
                     :headers="myHeaders"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload">
            <img v-if="form.wxpic"
                 :src="form.wxpic"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="广告来源">
          <el-radio-group v-model="form.adtype">
            <el-radio :label="1">文章</el-radio>
            <el-radio :label="2">广告</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="网站状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit">立即提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getSetup, upDataSetup } from '@/api/global'
export default {
  name: 'ArticleList',
  props: {},
  data () {
    return {
      myHeaders: '',
      vUrl: '',
      imageUrl: '',
      form: {
        id: 1,
        webtitle: '',
        copyright: '',
        beian: '',
        beianurl: '',
        vurl: '',
        vpic: '',
        url: '',
        wxpic: '',
        status: 1,
        adtype: 1
      }
    }
  },
  created () {
    this.loadtoken()
    this.loadgetSetup()
  },
  mounted () {

  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetSetup () {
      getSetup({ id: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.form = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onSubmit () {
      upDataSetup({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadgetSetup()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    handleVpicSuccess (res, file) {
      if (res.status === 'success') {
        this.form.vpic = res.data.url
      }
    },
    beforeVpicUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    handleAvatarSuccess (res, file) {
      if (res.status === 'success') {
        this.form.wxpic = res.data.url
      }
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 0px solid #ebeef5;
}
</style>
