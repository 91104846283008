<template>
  <div>
    <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24">
      <el-card shadow="never">
        <el-button type="primary"
                   icon="el-icon-plus"
                   size="mini"
                   style="margin: 0 10px 10px 0;"
                   @click="addclassify">添加</el-button>
                   <el-button type="success"
                   size="mini"
                   style="margin: 0 10px 10px 0;"
                   @click="classvideolist">主栏目视频</el-button>
        <el-table :data="classifylistdata"
                  style="width: 100%"
                  row-key="id"
                  border
                  lazy
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  :header-cell-style="{background:'#e8eaec',color:'#606266'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="80">
          </el-table-column>
          <el-table-column prop="cate_name"
                           label="分类名称">
          </el-table-column>
          <el-table-column prop="pic"
                           label="图标">
            <template slot-scope="scope">
              <div class="tabBox_img"
                   v-if="scope.row.pic!==''"><img :src="scope.row.pic"></div>
            </template>
          </el-table-column>
          <el-table-column prop="sort"
                           label="排序">
          </el-table-column>
          <el-table-column prop="is_show"
                           label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_show"
                         :active-value="1"
                         :inactive-value="2"
                         @change="onisshow(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center"
                           width="250">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="handleEdit(scope.row)"
                         icon="el-icon-edit">修改</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.row)"
                         icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <el-dialog :title="title"
               append-to-body
               :close-on-click-modal="false"
               :visible.sync="dialogaddclassify">
      <el-form ref="classify-form"
               :model="form"
               label-width="150px"
               :rules="formRule">
        <el-form-item label="类型"
                      prop="type">
          <el-select v-model="form.type"
                     placeholder="请选择类型">
            <el-option v-for="item in typedata"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称"
                      prop="cate_name"
                      placeholder="请输入分类名称">
          <el-input v-model="form.cate_name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="超链接"
                      prop="url">
          <el-input placeholder="请输入超链接"
                    v-model="form.url">
            <template slot="prepend">Http://</template>
          </el-input>
        </el-form-item>
        <el-form-item label="分类图标(180*180)">
          <el-upload class="avatar-uploader"
                     action="https://mpapi.ghtechwx.com/api/upFiles"
                     :headers="myHeaders"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl"
                 :src="imageUrl"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>-->
        <el-form-item label="排序">
          <el-input v-model="form.sort"
                    style="width:230px"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="数值越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.is_show">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="2">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit(type)">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { getClassifyList, getClassify, addClassify, stateClassify, editClassify, updataClassify, delClassify, getTypeList } from '@/api/global'
export default {
  name: 'ClassifyIndex',
  data () {
    return {
      dialogaddclassify: false,
      imageUrl: '',
      dialogImg: false,
      title: '',
      type: 1,
      myHeaders: '',
      form: {
        type: '',
        pid: 0,
        cate_name: '',
        url: '',
        sort: 0,
        pic: '',
        is_show: 1
      },
      classifydata: [],
      formRule: {
        type: [
          { required: true, message: '请输入选择类型', trigger: 'change' }
        ],
        pid: [
          { required: true, message: '请输入选择分类', trigger: 'blur' }
        ],
        cate_name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }]
      },
      classifylistdata: [],
      typedata: []
    }
  },
  created () {
    this.loadtoken()
    this.loadgetTypeList()
    this.loadgetClassify()
    this.loadgetClassifylist()
  },
  mounted () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetClassifylist () {
      getClassifyList().then(res => {
        if (res.data.status === 'success') {
          this.classifylistdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetTypeList () {
      getTypeList().then(res => {
        if (res.data.status === 'success') {
          this.typedata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetClassify () {
      getClassify().then(res => {
        if (res.data.status === 'success') {
          this.classifydata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    addclassify () {
      this.form = {
        pid: 0,
        cate_name: '',
        url: '',
        sort: 0,
        pic: '',
        is_show: 1
      }
      this.dialogaddclassify = true
      this.title = '分类添加'
      this.type = 1
    },
    onSubmit (type) {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['classify-form'].validate(valid => {
        if (!valid) {
          return false
        }
        if (type === 1) {
          this.addClassify()
        } else {
          this.updataClassify()
        }
      })
    },
    addClassify () {
      addClassify({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogaddclassify = false
          this.form.pid = 0
          this.form.cate_name = ''
          this.form.pic = ''
          this.imageUrl = ''
          this.loadgetClassifylist()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onisshow (res) {
      stateClassify({ id: res.id, is_show: res.is_show }).then(res => {

      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    handleEdit (row) {
      this.dialogaddclassify = true
      this.title = '分类修改'
      this.type = 2
      editClassify({ id: row.id }).then(res => {
        this.form = res.data.data
        this.imageUrl = res.data.data.pic
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    updataClassify () {
      updataClassify({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogaddclassify = false
          this.loadgetClassifylist()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delClassify({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadgetClassifylist()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleAvatarSuccess (res, file) {
      if (res.status === 'success') {
        this.form.pic = res.data.url
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    classvideolist () {
      this.$router.push({
        name: 'classvideo'
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 0px solid #ebeef5;
}
</style>
