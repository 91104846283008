<template>
  <div class="manage-container">
    <el-card class="box-card"
             shadow="hover">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form label-width="120px">
            <el-form-item label="搜索："
                          class="el-form-items">
              <el-input placeholder="请输入内容"
                        v-model="searchname"
                        clearable
                        class="input-with-select"
                        style="margin-top:5px">
                <el-button slot="append"
                           icon="el-icon-search"
                           :loading="updateLoading"
                           @click="searchs"></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="15">
          <el-button type="primary"
                     size="small"
                     icon="el-icon-plus"
                     @click="addArticleSubmit">新增文章</el-button>
          <el-button type="primary"
                     size="small"
                     icon="el-icon-plus"
                     @click="wxArticleSubmit">获取微信公众号文章</el-button>
          <el-button type="primary"
                     size="small"
                     icon="el-icon-plus"
                     @click="classSubmit">设置分类</el-button>
        </el-col>
        <el-col :span="3">
          <div class="right">
            <el-button type="primary"
                       size="small"
                       icon="el-icon-plus"
                       @click="synchronization"
                       :loading="updateLoading">同步官网</el-button>
          </div>
        </el-col>
      </el-row>
      <el-table :data="articleListdata"
                border
                :header-cell-style="{background:'#e8eaec',color:'#606266'}"
                :row-class-name="tableRowClassName"
                height="calc(100vh - 220px)"
                style="width: 100%;font-size: 14px"
                v-loading="loading"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="48">
        </el-table-column>
        <el-table-column label="缩略图"
                         prop="title"
                         align="center"
                         width="130">
          <template slot-scope="scope">
            <el-image :src="scope.row.thumb_urls"
                      lazy
                      style="width: 100%; height: 40px">
              <div slot="error"
                   class="image-slot">
                暂无图片
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="标题"
                         prop="title"
                         align="center"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="文章分类"
                         prop="catename"
                         align="center"
                         width="150"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="是否广告"
                         prop="adstatus"
                         align="center"
                         width="80"
                         :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.adstatus"
                       :active-value="2"
                       :inactive-value="1"
                       @change="onadstatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="作者"
                         prop="author"
                         align="center"
                         width="100"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="创建时间"
                         prop="update_time"
                         align="center"
                         width="160">
        </el-table-column>
        <el-table-column prop="status"
                         label="文章状态"
                         align="center"
                         width="90">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="2"
                       @change="onisshow(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="同步状态"
                         prop="status"
                         align="center"
                         width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.systatus=='已同步'"
                  class="green">{{scope.row.systatus}}</span>
            <span v-else
                  class="red">{{scope.row.systatus}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         width="180"
                         label="操作">
          <template slot-scope="scope">
            <el-button size="mini"
                       @click="handleEdit(scope.row)"
                       icon="el-icon-edit">修改</el-button>
            <el-button size="mini"
                       type="danger"
                       @click="handleDelete(scope.row)"
                       icon="el-icon-delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;"
           v-show="total!=0">
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-sizes="[10,20,30, 50, 100]"
                       :page-size="10"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisibletoken"
               append-to-body
               :close-on-click-modal="false"
               top="20px"
               :fullscreen="true">
      <el-form :model="form"
               label-width="80px"
               ref="article-form"
               :rules="formRule">
        <el-form-item label="分类"
                      prop="cateid">
          <el-select v-model="form.cateid"
                     placeholder="请选择分类">
            <el-option-group v-for="item in classifydata"
                             :key="item.id"
                             :label="item.name">
              <el-option v-for="items in item.catelist"
                         :key="items.id"
                         :label="items.cate_name"
                         :value="items.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="标题"
                      prop="title"
                      placeholder="请输入标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="作者"
                      prop="author"
                      placeholder="请输入作者">
          <el-input v-model="form.author"></el-input>
        </el-form-item>
        <el-form-item label="发布时间"
                      prop="create_time">
          <el-date-picker v-model="form.create_time"
                          type="date"
                          placeholder="选择日期"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="摘要"
                      prop="digest">
          <el-input v-model="form.digest"
                    type="textarea"
                    :rows="2"
                    placeholder="请输入摘要"></el-input>
        </el-form-item>
        <el-form-item label="封面">
          <el-upload class="thumb-uploader"
                     action="https://mpapi.ghtechwx.com/api/upImg"
                     :show-file-list="false"
                     :headers="myHeaders"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl"
                 :src="imageUrl"
                 class="thumb">
            <i v-else
               class="el-icon-plus thumb-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容"
                      prop="contents">
          <vue-ueditor-wrap v-model="form.contents"
                            :config="editorConfig"
                            mode="listener"
                            class="ueditor"></vue-ueditor-wrap>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateLoading"
                     @click="onSubmit(type)">确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
    <el-dialog title="获取微信公众号文章"
               :visible.sync="dialogFormVisiblewx"
               append-to-body
               :close-on-click-modal="false">
      <el-form :model="formwx"
               label-width="120px"
               ref="article-formwx"
               :rules="formRulewx">
        <el-form-item label="分类"
                      prop="cateid">
          <el-select v-model="formwx.cateid"
                     placeholder="请选择分类">
            <el-option-group v-for="item in classifydata"
                             :key="item.id"
                             :label="item.name">
              <el-option v-for="items in item.catelist"
                         :key="items.id"
                         :label="items.cate_name"
                         :value="items.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="文章链接"
                      prop="url"
                      placeholder="请输入链接">
          <el-input v-model="formwx.url"></el-input>
        </el-form-item>
        <el-form-item label="是否同步官网"
                      prop="issynchro">
          <el-radio v-model="formwx.issynchro"
                    label="是">是</el-radio>
          <el-radio v-model="formwx.issynchro"
                    label="否">否</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :loading="updateLoading"
                     @click="onSubmitwx">确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
    <el-dialog title="设置分类"
               append-to-body
               :visible.sync="dialogaddclassify">
      <el-form ref="classify-form"
               :model="classifyform"
               label-width="150px"
               :rules="formclassRule">
        <el-form-item label="分类"
                      prop="cateid">
          <el-select v-model="classifyform.cateid"
                     placeholder="请选择分类">
            <el-option-group v-for="item in classifydata"
                             :key="item.id"
                             :label="item.name">
              <el-option v-for="items in item.catelist"
                         :key="items.id"
                         :label="items.cate_name"
                         :value="items.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="classonSubmita()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { getArticleList, editArticle, addArticle, updateArticle, stateArticle, delArticle, getSynchro, getWxArticle, setAd, setClass, getClassify } from '@/api/global'
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  name: 'ArticleList',
  components: {
    VueUeditorWrap
  },
  props: {},
  data () {
    return {
      loading: true,
      updateLoading: false,
      dialogaddclassify: false,
      dialogFormVisiblewx: false,
      currentPage: 1,
      total: 0,
      perpage: 10,
      title: '',
      type: 1,
      dialogFormVisibletoken: false,
      articleListdata: [],
      search: '',
      articleSelectiondata: [],
      myHeaders: '',
      imageUrl: '',
      classifyform: {
        cateid: ''
      },
      classifydata: [],
      searchname: '',
      form: {
        cateid: '',
        title: '',
        author: '光华科技',
        digest: '',
        thumb_urls: '',
        create_time: '',
        status: 1,
        contents: ''
      },
      formwx: {
        cateid: '',
        url: '',
        issynchro: '否'
      },
      formRule: {
        cateid: [{ required: true, message: '请选择分类', trigger: 'change' }],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        create_time: [{ required: true, message: '请选择发布时间', trigger: 'blur' }],
        digest: [{ required: true, message: '请输入摘要', trigger: 'blur' }],
        contents: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      formRulewx: {
        cateid: [{ required: true, message: '请选择分类', trigger: 'change' }],
        url: [{ required: true, message: '请输入链接', trigger: 'blur' }]
      },
      formclassRule: {
        cateid: [{ required: true, message: '请选择分类', trigger: 'change' }]
      },
      editorConfig: {
        // 初始容器高度
        initialFrameHeight: 500,
        // 编辑器层级的基数
        zIndex: 2003,
        // 否启用元素路径
        elementPathEnabled: false,
        // 是否开启字数统计
        wordCount: false,
        // 允许的最大字符数
        maximumWords: 1000000,
        // focus时自动清空初始化时的内容
        autoClearinitialContent: false,
        // 关闭elementPath
        autoFloatEnabled: false,
        autoHeightEnabled: false,
        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/static/UEditor/',
        // 服务端接口（
        // serverUrl: '//ueditor.szcloudplus.com/cos'
        serverUrl: 'https://mpapi.ghtechwx.com/api/UEditorFile?token=' + this.loadtoken(),
        toolbars: [
          ['source', 'Undo', 'Redo', 'bold', 'italic', 'removeformat', 'forecolor', 'backcolor', 'justifyleft', 'justifycenter', 'justifyright', 'link', 'unlink', 'simpleupload', 'insertvideo', 'music', 'RemoveFormat', 'imageleft', 'imageright', 'wordimage']
        ]

      },
      msg: ''
    }
  },
  created () {
    this.loadtoken()
    this.loadArticleList()
    this.loadgetClassify()
  },
  mounted () {

  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = {
        Authorization: user.token
      }
      return user.token
    },
    loadgetClassify () {
      getClassify({ type: 1 }).then(res => {
        if (res.data.status === 'success') {
          this.classifydata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadArticleList () {
      getArticleList({ searchname: this.searchname, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.articleListdata = res.data.data.page_items
          this.total = res.data.data.total
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    addArticleSubmit () {
      this.form = {
        cateid: '',
        title: '',
        author: '光华科技',
        digest: '',
        thumb_urls: '',
        create_time: '',
        status: 1,
        contents: ''
      }
      this.imageUrl = ''
      this.dialogFormVisibletoken = true
      this.title = '添加文章'
      this.type = 1
    },
    onSubmit (type) {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['article-form'].validate(valid => {
        if (!valid) {
          return false
        }
        if (type === 1) {
          this.addArticle()
        } else {
          this.updateArticle()
        }
      })
    },
    addArticle () {
      this.updateLoading = true
      addArticle({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.loadArticleList()
          this.updateLoading = false
          this.dialogFormVisibletoken = false
          this.form = {
            cateid: '',
            title: '',
            author: '光华科技',
            digest: '',
            thumb_urls: '',
            create_time: '',
            status: 1,
            contents: ''
          }
          this.imageUrl = ''
          this.loadgetClassifylist()
        } else {
          this.updateLoading = false
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    updateArticle () {
      this.updateLoading = true
      updateArticle({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.updateLoading = false
          this.dialogFormVisibletoken = false
          this.imageUrl = ''
          this.loadArticleList()
        } else {
          this.updateLoading = false
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    searchs () {
      this.loading = true
      this.updateLoading = true
      getArticleList({ searchname: this.searchname, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.articleListdata = res.data.data.page_items
          this.loading = false
          this.updateLoading = false
          this.currentPage = res.data.page
          this.total = res.data.total
        } else {
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      getArticleList({ searchname: this.searchname, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.loading = false
          this.currentPage = res.data.data.page
          this.total = res.data.data.total
          this.articleListdata = res.data.data.page_items
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.loading = true
      getArticleList({ searchname: this.searchname, page: val, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.currentPage = res.data.data.page
          this.total = res.data.data.total
          this.articleListdata = res.data.data.page_items
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleSelectionChange (val) {
      this.articleSelectiondata = []
      this.articleSelectiondata = val
    },
    onisshow (res) {
      stateArticle({ id: res.id, status: res.status }).then(res => {

      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onadstatus (res) {
      setAd({ id: res.id, status: res.adstatus }).then(res => {
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    handleEdit (row) {
      this.dialogFormVisibletoken = true
      this.title = '文章修改'
      this.type = 2
      editArticle({ id: row.id }).then(res => {
        this.form = res.data.data
        this.imageUrl = res.data.data.thumb_urls
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delArticle({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadArticleList()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 同步官网
    synchronization () {
      const self = this
      if (this.articleSelectiondata.length === 0) {
        this.$message.error('请选择文章')
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: '正在同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.95)'
      })

      this.updateLoading = true
      getSynchro({ data: this.articleSelectiondata }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })

          self.updateLoading = false
          self.articleSelectiondata = []
          self.loadArticleList()
          loading.close()
        } else {
          loading.close()
          this.updateLoading = false
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        loading.close()
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      } else {
        return 'success-row'
      }
    },
    deptnamefilterTags (value, row) {
      return row.deptname === value
    },
    rulenamefilterTags (value, row) {
      return row.rulename === value
    },
    handleAvatarSuccess (res, file) {
      if (res.status === 'success') {
        this.form.thumb_urls = res.data.url
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    },
    beforeAvatarUpload (file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isJPG) {
      //  this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      // return isJPG && isLt2M
      return isLt2M
    },
    wxArticleSubmit () {
      this.dialogFormVisiblewx = true
    },
    onSubmitwx () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['article-formwx'].validate(valid => {
        if (!valid) {
          return false
        }
        const loading = this.$loading({
          lock: true,
          text: '正在获取文章中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.95)'
        })
        getWxArticle({ url: this.formwx.url, issynchro: this.formwx.issynchro, cateid: this.formwx.cateid }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.dialogFormVisiblewx = false
            this.formwx.url = ''
            this.formwx.issynchro = '否'
            this.updateLoading = false
            this.loadArticleList()
            loading.close()
          } else {
            loading.close()
            this.updateLoading = false
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          loading.close()
          this.updateLoading = false
          console.log('保存失败', err)
        })
      })
    },
    classSubmit () {
      if (this.articleSelectiondata.length === 0) {
        this.$message.error('请选择文章')
        return false
      }
      this.dialogaddclassify = true
    },
    classonSubmita () {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['classify-form'].validate(valid => {
        if (!valid) {
          return false
        }
        const self = this
        const loading = this.$loading({
          lock: true,
          text: '正在设置文章中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.95)'
        })
        setClass({ data: this.articleSelectiondata, cateid: this.classifyform.cateid }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.dialogaddclassify = false
            self.articleSelectiondata = []
            self.loadArticleList()
            loading.close()
          } else {
            loading.close()
            this.dialogaddclassify = false
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          loading.close()
          this.dialogaddclassify = false
          console.log('保存失败', err)
        })
      })
    },
    handleChange (info) {
      console.log(info)
    }

  }
}
</script>

<style lang="less" scoped>
.preview-image-wrap {
  /* Ensure the size of the image fit the container perfectly */
  .preview-image {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
    height: 200px;
  }
}
.avatar {
  margin-top: 50px;
  margin-left: 60px;
  p {
    padding-left: 35px;
    font-size: 14px;
  }
}
.addbutton {
  padding-bottom: 15px;
}
/deep/.el-date-editor--time-select {
  margin: 0 10px;
}

/deep/.el-form-item__content {
  line-height: 20px;
  font-size: 14px;
}
/deep/.el-table .warning-row {
  background: #f6f6f6; // 这里可以修改颜色
}

/deep/.el-table .success-row {
  background: #ffffff; // 这里可以修改颜色
}
/deep/.el-table {
  overflow: visible !important;
}
/deep/.el-table td,
.el-table th {
  padding: 8px 0;
}
/deep/.el-table .green_font {
  color: #10aa10;
  font-weight: 700;
}
.red {
  color: red;
}
.green {
  color: #10aa10;
}
.right {
  float: right;
}
.edui-default {
  z-index: 2003 !important;
}
/deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
/deep/ .el-upload:hover {
  border-color: #409eff;
}
.thumb-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.thumb {
  width: 80px;
  height: 80px;
  display: block;
}
.view img {
  width: 100%;
  height: 100%;
}
</style>
