<template>
  <el-menu class="nav-menu"
           :default-active="$route.path"
           background-color="#25282b"
           text-color="#fff"
           active-text-color="#fff"
           router
           :unique-opened="true"
           :collapse="isCollapse">
    <el-menu-item index="">
      <template slot="title"><img src="../../../assets/image/logo.png"
             height="30"></template>
    </el-menu-item>
    <template v-for="item in navdata">
      <el-menu-item :key="item.id"
                    :index="item.url"
                    v-if="item.ismenu===1">
        <i :class="item.icon"></i>
        <span slot="title">{{item.name}}</span>
      </el-menu-item>
      <el-submenu :key="item.id"
                  :index="item.url"
                  v-else>
        <template slot="title">
          <i :class="item.icon"></i>
          <span slot="title">{{item.name}}</span>
        </template>
        <el-menu-item-group v-for="subitem in item.children"
                            :key="subitem.id">
          <el-menu-item :index="subitem.url">{{subitem.name}}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </template>
  </el-menu>
</template>

<script>
import { getNavList } from '@/api/global'
export default {
  name: 'AppAside',
  props: ['is-collapse'],
  data () {
    return {
      navdata: []
    }
  },
  mounted () {
    this.loadNavlist()
  },
  methods: {
    loadNavlist () {
      getNavList().then(res => {
        if (res.data.status === 'success') {
          this.navdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.nav-menu {
  .iconfont {
    margin-right: 10px;
    padding-left: 5px;
  }

  .el-menu-item.is-active {
    background: #e62129 !important;
  }
}
</style>
