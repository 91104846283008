<template>
<div>
      <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24">
      <el-card shadow="never">
  <el-table :data="videolistdata"
                  style="width: 100%"
                  row-key="id"
                  border
                  lazy
                  :header-cell-style="{background:'#e8eaec',color:'#606266'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="80">
          </el-table-column>
          <el-table-column prop="name"
                           label="栏目">
          </el-table-column>
          <el-table-column prop="pic"
                           label="缩略图">
            <template slot-scope="scope">
              <div class="tabBox_img"
                   v-if="scope.row.pic!==''"><img :src="scope.row.pic"></div>
            </template>
          </el-table-column>
          <el-table-column prop="status"
                           label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status"
                         :active-value="1"
                         :inactive-value="2"
                         @change="onisshow(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center"
                           width="250">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="handleEdit(scope.row)"
                         icon="el-icon-edit">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        </el-card>
       </el-col>
        <el-dialog :title="title"
               append-to-body
               :close-on-click-modal="false"
               :visible.sync="dialogaddad">
      <el-form ref="ad-form"
               :model="form"
               label-width="150px"
               :rules="formRule">
        <el-form-item label="栏目"
                      prop="name"
                      placeholder="请输入视频标题">
          <el-input v-model="form.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="上传视频">
          <uploader :options="options"
                    @file-success="onFileSuccess"
                    :fileStatusText="fileStatusText" class="uploader">
            <uploader-unsupport></uploader-unsupport>
            <uploader-drop>
              <uploader-btn :attrs="attrs"
                            style="background-color: #67C13B"><i class="el-icon-upload"
                   style="margin-right: 5px"></i>上传视频</uploader-btn>
            </uploader-drop>
            <uploader-list></uploader-list>
          </uploader>
        </el-form-item>
        <el-form-item label="缩略图(640*360)">
          <el-upload class="avatar-uploader"
                     action="https://mpapi.ghtechwx.com/api/upImg"
                     :headers="myHeaders"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl"
                 :src="imageUrl"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="2">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
</div>
</template>
<script>
import { getClassVideo, editClassVideo, updateClassVideo, stateClassvideo, videoUpFile } from '@/api/global'
export default {
  name: 'classvideoIndex',
  data () {
    return {
      dialogaddad: false,
      imageUrl: '',
      dialogImg: false,
      updateLoading: false,
      controllerErrorFileDialog: false,
      title: '',
      type: 1,
      myHeaders: '',
      form: {
        id: '',
        name: '',
        type: '',
        pic: '',
        url: ''
      },
      searchname: '',
      addata: [],
      formRule: {
        cateid: [{ required: true, message: '请选择分类', trigger: 'change' }],
        title: [{ required: true, message: '请输入视频标题', trigger: 'blur' }]
      },
      videolistdata: [],
      scanning: [],
      classifydata: [],
      options: {
        target: 'https://mpapi.ghtechwx.com/api/upFiles?action=Upload',
        testChunks: false,
        singleFile: true,
        chunkSize: 1024 * 1024 * 3, // 2MB
        simultaneousUploads: 1, // 并发上传数
        headers: {
          Authorization: (JSON.parse(window.localStorage.getItem('user'))).token
        },
        maxChunkRetries: 1, // 最大自动失败重试上传次数
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) { // 格式化时间
          return parsedTimeRemaining
            .replace(/\syears?/, '年')
            .replace(/\days?/, '天')
            .replace(/\shours?/, '小时')
            .replace(/\sminutes?/, '分钟')
            .replace(/\sseconds?/, '秒')
        }
      },
      fileStatusText: {
        success: '',
        error: '上传失败',
        uploading: '上传中...',
        paused: '暂停',
        waiting: '等待中...'
      },
      attrs: {
        accept: ['video/mp4']
      }
    }
  },
  created () {
    this.loadtoken()
    this.loadgetClassVideo()
  },
  mounted () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetClassVideo () {
      getClassVideo().then(res => {
        if (res.data.status === 'success') {
          this.videolistdata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    handleEdit (row) {
      this.title = '修改'
      this.dialogaddad = true
      editClassVideo({ id: row.id }).then(res => {
        this.form = res.data.data
        this.imageUrl = res.data.data.pic
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    onisshow (res) {
      stateClassvideo({ id: res.id, status: res.status }).then(res => {
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onSubmit () {
      this.updateClassVideo()
    },
    updateClassVideo () {
      updateClassVideo({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogaddad = false
          this.loadgetClassVideo()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleAvatarSuccess (res, file) {
      if (res.status === 'success') {
        this.form.pic = res.data.url
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    scanningsuccess (res) {
      if (res.status === 'success') {
        this.scanning = this.scanning.concat(res.data)
        this.form.url = res.data.url
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    scanningRemove (file) {
      this.scanning.some((item, i) => {
        if (item.url === file.url) {
          this.scanning.splice(i, 1)
          return true
        }
      })
    },
    beforeUpload (file) {
      /* var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'pdf'
      if (!extension) {
        this.$message({
          message: '上传文件只能是pdf格式!',
          type: 'warning'
        })
        return false
      }
      return extension */
    },
    onFileSuccess (rootFile, file, response, chunk) {
      const resp = JSON.parse(response)
      // 合并分片
      if (resp.data.vurl === null) {
        videoUpFile({
          filename: file.name,
          identifier: file.uniqueIdentifier,
          totalSize: file.size,
          totalChunks: chunk.offset
        }).then(res => {
          if (res.data.data.code === 0) {
            this.form.url = res.data.data.vurl
            this.fileStatusText.success = '上传成功'
          } else {
            this.$message.error('上传失败')
            this.fileStatusText.success = '上传失败'
            this.fileStatusText.error = '上传失败'
          }
        }).catch(err => {
          this.fileStatusText.error = '上传失败'
          console.log('上传失败', err)
        })
      } else {
        this.fileStatusText.success = '上传成功'
        this.form.url = resp.data.vurl
      }
    }

  }
}
</script>
<style lang="less" scoped>
/deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 0px solid #ebeef5;
}
.el-form-items {
  float: left;
}
// 文件上传组件
.uploader {
    padding: 15px;
    font-size: 14px;
    .uploader-btn {
        margin-right: 4px;
        color: #fff;
        padding: 6px 16px;
    }
    .upfile{
        border: 1px solid #409eff;
        background: #409eff;
    }
    .updir{
        border: 1px solid #67c23a;
        background: #67c23a;
    }
    .uploader-list {
        max-height: 440px;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
    // /deep/.uploader-drop{
    //     height: 400px;
    // }
}
//手机等小屏幕手持设备。当设备宽度  在  320px和768px之间时,执行当前的css
@media only screen and (min-width: 320px) and (max-width: 768px) {
    .uploader {
        width: 98%;
        padding: 0;
        box-shadow: none;
    }
}
</style>
