<template>
  <el-container class="layout-container">
    <el-aside class="aside"
              width="auto">
      <app-aside class="aside-menu"
                 :is-collapse="isCollapse" />
    </el-aside>
    <el-container>
      <el-header class="header">
        <div>
          <i :class="{
              'el-icon-s-fold': isCollapse,
              'el-icon-s-unfold': !isCollapse
            }"
             @click="isCollapse = !isCollapse"
             :title="isCollapse ? '展开' : '收起'"></i>
          <span></span>
        </div>
        <el-badge class="bellitem">
          <i class="el-icon-bell"></i>
        </el-badge>
        <el-dropdown>
          <div class="avatar-wrap">
            <img class="avatar"
                 :src="headimgurl">
            <span>{{userinfo.name}}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="onseting">个人中心</el-dropdown-item>
            <el-dropdown-item @click.native="onLogout">安全退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>
      <el-main class="main">
        <!-- 子路由出口 -->
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppAside from './components/aside'
import { getUserProfile } from '@/api/user'
import globalBus from '@/utils/global-bus'
export default {
  name: 'LayoutIndex',
  components: {
    AppAside
  },
  data () {
    return {
      userinfo: {}, // 当前登录用户信息
      isCollapse: false,
      headimgurl: '',
      status: '',
      navname: '',
      topname: ''
    }
  },
  created () {
    // 注册自定义事件
    globalBus.$on('personals', (res) => {
      this.headimgurl = res.headimgurl
    })
    globalBus.$on('navinfo', (res) => {
      this.status = res.status
      this.navname = res.navname
      this.topname = res.topname
    })
  },
  mounted () {
    // 组件初始化
    this.loadUserProfile()
  },
  methods: {
    loadUserProfile () {
      getUserProfile().then(res => {
        this.userinfo = res.data.data
        if (res.data.data.headimgurl === undefined || res.data.data.headimgurl <= 0) {
          this.headimgurl = 'https://mpapi.ghtechwx.com/uploads/image/20211015/1657895623edb24ea.jpg'
        } else {
          this.headimgurl = 'https://mpapi.ghtechwx.com/' + res.data.data.headimgurl
        }
      })
    },
    onLogout () {
      this.$confirm('确认退出吗？', '退出提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 把用户的登录状态清除
        window.localStorage.removeItem('user')
        // 跳转到登录页面
        this.$router.push('/login')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出'
        })
      })
    },
    onseting () {
      this.$router.push('/setting')
    }
  }
}
</script>

<style lang="less" scoped>
.layout-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.aside {
  background-color: #25282b;
  .aside-menu {
    position: relative;
    height: 100%;
  }
}
/deep/.el-submenu .el-menu-item {
  min-width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  .avatar-wrap {
    display: flex;
    align-items: center;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
.main {
  background-color: #f5f5f5;
  padding: 10px;
}
.bellitem {
  position: absolute;
  right: 150px;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #000;
  cursor: text;
}
.clearfix {
  position: absolute;
  margin-left: 60px;
}
</style>
