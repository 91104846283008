<template>
  <div>
    <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24">
      <el-card shadow="never">
        <el-button type="primary"
                   icon="el-icon-plus"
                   size="mini"
                   style="margin: 0 10px 10px 0;"
                   @click="addad">添加</el-button>
        <el-table :data="adlistdata"
                  style="width: 100%"
                  row-key="id"
                  border
                  lazy
                  :header-cell-style="{background:'#e8eaec',color:'#606266'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="80">
          </el-table-column>
          <el-table-column prop="title"
                           label="广告名称">
          </el-table-column>
          <el-table-column prop="pic"
                           label="图标">
            <template slot-scope="scope">
              <div class="tabBox_img"
                   v-if="scope.row.pic!==''"><img :src="scope.row.pic"></div>
            </template>
          </el-table-column>
          <el-table-column prop="sort"
                           label="排序">
          </el-table-column>
          <el-table-column prop="status"
                           label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status"
                         :active-value="1"
                         :inactive-value="2"
                         @change="onisshow(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center"
                           width="250">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="handleEdit(scope.row)"
                         icon="el-icon-edit">修改</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.row)"
                         icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <el-dialog :title="title"
               append-to-body
               :close-on-click-modal="false"
               :visible.sync="dialogaddad">
      <el-form ref="ad-form"
               :model="form"
               label-width="150px"
               :rules="formRule">
        <el-form-item label="广告名称"
                      prop="title"
                      placeholder="请输入广告名称">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="超链接"
                      prop="url">
          <el-input placeholder="请输入超链接"
                    v-model="form.url">
          </el-input>
        </el-form-item>
        <el-form-item label="广告图(180*180)">
          <el-upload class="avatar-uploader"
                     action="https://mpapi.ghtechwx.com/api/upFiles"
                     :headers="myHeaders"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl"
                 :src="imageUrl"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort"
                    style="width:230px"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="数值越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="2">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit(type)">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { getAdList, addAd, getEditAd, upDataAd, getDelAd, stateAd } from '@/api/global'
export default {
  name: 'AdIndex',
  data () {
    return {
      dialogaddad: false,
      imageUrl: '',
      dialogImg: false,
      title: '',
      type: 1,
      myHeaders: '',
      form: {
        title: '',
        url: '',
        sort: 0,
        pic: '',
        status: 1
      },
      addata: [],
      formRule: {
        title: [{ required: true, message: '请输入广告名称', trigger: 'blur' }]
      },
      adlistdata: []
    }
  },
  created () {
    this.loadtoken()
    this.loadgetAdList()
  },
  mounted () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetAdList () {
      getAdList().then(res => {
        if (res.data.status === 'success') {
          this.adlistdata = res.data.data.page_items
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    addad () {
      this.dialogaddad = true
      this.title = '广告添加'
      this.type = 1
    },
    onSubmit (type) {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['ad-form'].validate(valid => {
        if (!valid) {
          return false
        }
        if (type === 1) {
          this.addAd()
        } else {
          this.upDataAd()
        }
      })
    },
    addAd () {
      addAd({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogaddad = false
          this.form.title = ''
          this.form.pic = ''
          this.imageUrl = ''
          this.loadgetAdList()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onisshow (res) {
      stateAd({ id: res.id, is_show: res.is_show }).then(res => {

      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    handleEdit (row) {
      this.dialogaddad = true
      this.title = '广告修改'
      this.type = 2
      getEditAd({ id: row.id }).then(res => {
        this.form = res.data.data
        this.imageUrl = res.data.data.pic
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    upDataAd () {
      upDataAd({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogaddad = false
          this.loadgetAdList()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDelAd({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadgetAdList()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleAvatarSuccess (res, file) {
      if (res.status === 'success') {
        this.form.pic = res.data.url
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 0px solid #ebeef5;
}
</style>
