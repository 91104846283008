<template>
  <div style="margin: -8px;">
    <div class="header">
      <div class="logo"><img class="logoimage"
             src="~@/assets/image/bglogo.png" /></div>
    </div>
    <div class="content">
      <div class="login-newbg"></div>
      <div class="login-adlink">
        <a href=""
           target="_blank"></a>
      </div>
      <div class="content-layout">
        <div class="login-box-warp">
          <div class="login-box">
            <el-form class="login-form"
                     ref="login-form"
                     :model="user"
                     :rules="formRules">
              <el-form-item>
                <div class="tab"
                     id="tab">
                  <span class="canslien current"
                        style="cursor: pointer;"
                        onclick="cooperation(1);">账号登录</span>
                  <!--<span class="canslien_1"
                        style="cursor: pointer"
                        onclick="cooperation(2);"><i></i>短信登录</span>-->
                </div>
              </el-form-item>
              <el-form-item prop="account">
                <el-input v-model="user.account"
                          placeholder="请输入手机号或邮箱"
                          prefix-icon="el-icon-user"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="user.password"
                          show-password
                          placeholder="请输入密码"
                          prefix-icon="el-icon-lock"></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input class="code"
                          v-model="user.code"
                          placeholder="请输入验证码"
                          prefix-icon="el-icon-key"></el-input>

                <div class="codebutton"
                     @click="refreshCode">
                  <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button class="login-btn"
                           type="danger"
                           :loading="loginLoading"
                           @click="onLogin">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="login_footer_box">
      <div class="login_footer">
        <div class="copyright">
          <p><span class="icp"><a href="https://beian.miit.gov.cn/#/Integrated/index"
                 target="_blank">粤ICP备18023756号-3</a> | Copyright 2021 广东光华科技股份有限公司</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SIdentify from '@/components/identify'
import { login } from '@/api/user'
export default {
  name: 'Login',
  data () {
    const validateCode = (rule, value, callback) => {
      // console.log(value)
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else if (value !== this.identifyCode) {
        callback(new Error('验证码错误'))
      } else {
        callback()
      }
    }
    return {
      codestatus: false,
      user: {
        account: '',
        password: '',
        code: ''
      },
      makeCode: '',
      identifyCode: '',
      accuracy: 1,
      loginLoading: false,
      dialogTableVisible: false,
      formRules: {
        account: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3|5|6|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        code: [
          { validator: validateCode, trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    's-identify': SIdentify
  },
  mounted () {
    // 绑定事件
    window.addEventListener('keydown', this.keyDown)
    this.makeIdentifyCode({ randomTypeLen: true })
  },
  methods: {
    onLogin () {
      this.$refs['login-form'].validate(valid => {
        if (!valid) {
          return false
        }
        this.login()
      })
    },
    login () {
      this.loginLoading = true
      login(this.user).then(res => {
        if (res.data.status === 'success') {
          this.loginLoading = false
          window.localStorage.setItem('user', JSON.stringify(res.data))
          // 跳转到首页
          // this.$router.push({
          //  name: 'home'
          // })
          this.$router.replace('/')
        } else {
          this.$message.error(res.data.message)
          this.loginLoading = false
        }
      }).catch(err => {
        console.log('登录失败', err)
        // this.$message.error('登录失败，手机号或密码错误')
        this.loginLoading = false
      })
    },
    cedesuccess () {
      this.user.code = this.createCode()
    },
    cedeerror () {
      this.user.code = ''
    },
    createCode () {
      var code = ''
      var codeLength = 4
      var random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      for (var i = 0; i < codeLength; i++) {
        var index = Math.floor(Math.random() * 9)
        code += random[index]
      }
      return code
    },
    keyDown (e) {
      // 如果是回车则执行登录方法
      if (e.keyCode === 13) {
        this.onLogin()
      }
    },
    about () {
      this.dialogTableVisible = true
    },
    /**
     * 随机生成一个0~9之间的数
     */
    randomNum () {
      return Math.floor(Math.random() * 10)
    },

    /**
     * 随机生成一个字母
     * @param {boolean} isUupper 生成大写字母
     */
    randomAlphabet ({ isUupper = false } = {}) {
      // a的Unicode值为97,z的Unicode值为123
      const alphabet = String.fromCharCode(Math.floor(Math.random() * 25) + 97)
      if (!isUupper) {
        return alphabet
      } else {
        return alphabet.toUpperCase()
      }
    },

    /**
     * 生成图片验证码
     * @param {number} length 图片验证码位数
     * @param {boolean} typeMix 数字和字母混合
     * @param {string} pureNumber 纯数字('number')或者字母('alphabet')
     * @param {boolean} randomTypeLen 随机生成类型个数组合
     * @param {boolean} capsLookMix 字母大小写混合
     * @param {number} numLength 混合类型下的数字个数
     * @param {number} uupperLength 大写字母的个数
     */
    makeIdentifyCode ({ length = 4, typeMix = true, pureNumber = 'alphabet', randomTypeLen = false, capsLookMix = false, numLength = 2, uupperLength = 1 } = {}) {
      this.makeCode = ''
      // 数字和字母混合
      if (typeMix) {
        // 随机生成类型个数组合
        if (randomTypeLen) {
          // 字母大小写混合
          if (capsLookMix) {
            const numLength = Math.floor(Math.random() * length) + 1
            const uupperLength = numLength === length ? 0 : Math.floor(Math.random() * (length - numLength)) + 1
            for (let i = 0; i < numLength; i++) {
              this.makeCode += this.randomNum()
            }
            for (let i = 0; i < uupperLength; i++) {
              this.makeCode += this.randomAlphabet({ isUupper: true })
            }
            if (numLength + uupperLength < length) {
              for (let i = 0; i < length - numLength - uupperLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          } else {
            const numLength = Math.floor(Math.random() * length) + 1
            for (let i = 0; i < numLength; i++) {
              this.makeCode += this.randomNum()
            }
            if (numLength < length) {
              for (let i = 0; i < length - numLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          }
        } else {
          // 字母大小写混合
          if (capsLookMix) {
            const tempNumLength = numLength < 0 ? 2 : numLength > length ? 2 : numLength
            const tempUupperLength = uupperLength < 0 ? 1 : uupperLength > length - tempNumLength ? 1 : uupperLength
            for (let i = 0; i < tempNumLength; i++) {
              this.makeCode += this.randomNum()
            }
            for (let i = 0; i < tempUupperLength; i++) {
              this.makeCode += this.randomAlphabet({ isUupper: true })
            }
            if (tempNumLength + tempUupperLength < length) {
              for (let i = 0; i < length - tempNumLength - tempUupperLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          } else {
            const tempNumLength = numLength < 0 ? 2 : numLength > length ? 2 : numLength
            for (let i = 0; i < tempNumLength; i++) {
              this.makeCode += this.randomNum()
            }
            if (tempNumLength < length) {
              for (let i = 0; i < length - tempNumLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          }
        }
      } else {
        // 纯数字('number')
        if (pureNumber === 'number') {
          for (let i = 0; i < length; i++) {
            this.makeCode += this.randomNum()
          }
        }
        // 纯字母('alphabet')
        if (pureNumber === 'alphabet') {
          // 字母大小写混合
          if (capsLookMix) {
            const tempUupperLength = uupperLength < 0 ? 1 : uupperLength > length ? 1 : uupperLength
            for (let i = 0; i < tempUupperLength; i++) {
              this.makeCode += this.randomAlphabet({ isUupper: true })
            }
            if (tempUupperLength < length) {
              for (let i = 0; i < length - tempUupperLength; i++) {
                this.makeCode += this.randomAlphabet()
              }
            }
          } else {
            for (let i = 0; i < length; i++) {
              this.makeCode += this.randomAlphabet()
            }
          }
        }
      }
      this.shuffle(this.makeCode)
    },

    /**
     * 图片验证码随机排序
     * @param {string} str 图片验证码
     */
    shuffle (str) {
      this.identifyCode = [...str].sort(() => Math.random() - 0.5).join('')
    },

    /**
     * 更换图片验证码
     */
    refreshCode () {
      this.makeIdentifyCode({ randomTypeLen: true })
    }
  }
}
</script>

<style lang="less" scoped>
.clearfix {
  zoom: 1;
}
.header {
  width: 100%;
  background-color: #ffffff;
  line-height: 80px;
  height: 80px;
}
.logo {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
}
.logoimage {
  margin-top: 20px;
}
.clearfix:after,
.clearfix:before {
  content: ' ';
  display: table;
}
.content {
  width: 100%;
  position: relative;
}
.login-newbg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
  height: 600px;
  background-image: url('~@/assets/image/5d24495b2940d.png');
}
.login-adlink {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
.login-adlink a {
  position: absolute;
  width: 630px;
  height: 440px;
  left: 80px;
  top: 80px;
}
.login-adlink a {
  left: 10px;
}
.content .content-layout {
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  height: 600px;
  z-index: 2;
}
.login-box-warp {
  position: absolute;
  top: 120px;
  right: 60px;
  width: 350px;
}
.login-box {
  width: 350px;
  min-height: 350px;
  padding: 25px 25px 23px;
  color: #6c6c6c;
  background: #fff;
  position: relative;
  margin: 0 auto;
}
.login-box .code {
  width: 60%;
  float: left;
}
.login-box .codebutton {
  width: 35%;
  float: left;
  margin-left: 2%;
}
.login-box .tab {
  height: 26px;
  padding: 10px 0 0 0;
}
.login-box .tab span.current {
  color: #666;
}
.login-box .tab span {
  color: #666;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  float: left;
  text-align: left;
  cursor: default;
  width: 50% !important;
  position: relative;
}
.login-box .tab span.canslien i {
  display: block;
  position: absolute;
  width: 1px;
  height: 20px;
  background-color: #ccc;
  right: 0;
  top: 2px;
}
.login-box .login-btn {
  width: 100%;
  height: 50px;
}
.login_footer_box {
  position: relative;
  width: 100%;
  margin-top: 30px;
}
.login_footernew {
  max-width: 1440px;
  margin: 0 auto 33px;
}
ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.clearfix:after {
  clear: both;
  content: ' ';
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}
.login_footer .row_box {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  padding: 8px 0 0;
}
.login_footer .copyright {
  color: #666;
  line-height: 20px;
  padding: 15px 0 0 170px;
  width: 1000px;
  margin: 0 auto;
  font-size: 14px;
}
.record_number {
  display: inline-block;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background: url('~@/assets/image/beian.png') no-repeat;
}
.copyright p .footer-a {
  vertical-align: middle;
}
.login_footer .copyright a {
  color: #666;
}
.copyright .icp {
  display: inline-block;
  vertical-align: middle;
}
</style>
