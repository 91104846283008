import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import uploader from 'vue-simple-uploader'

Vue.use(uploader)
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$ue = '' // 开发运行时改为"",打包时与vue打包路径一致
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
