import request from '@/utils/request'
//  获取户名
export const getAccountNameList = data => {
  return request({
    method: 'POST',
    url: '/api/getAccountNameList',
    data
  })
}
// 获取部门
export const getDepartmentList = data => {
  return request({
    method: 'POST',
    url: '/api/getDepartmentList',
    data
  })
}
export const addNewsDepartment = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsDepartment',
    data
  })
}
export const getdelDepartment = data => {
  return request({
    method: 'POST',
    url: '/api/getDelDepartment',
    data
  })
}
export const getEditDepartment = data => {
  return request({
    method: 'POST',
    url: '/api/getEditDepartment',
    data
  })
}
export const upDataDepartment = data => {
  return request({
    method: 'POST',
    url: '/api/upDataDepartment',
    data
  })
}
// 角色
export const getRoleList = data => {
  return request({
    method: 'POST',
    url: '/api/getRoleList',
    data
  })
}
export const addNewsRole = data => {
  return request({
    method: 'POST',
    url: '/api/addNewsRole',
    data
  })
}
export const getDelRole = data => {
  return request({
    method: 'POST',
    url: '/api/getDelRole',
    data
  })
}
export const getEditRole = data => {
  return request({
    method: 'POST',
    url: '/api/getEditRole',
    data
  })
}
export const upDataRole = data => {
  return request({
    method: 'POST',
    url: '/api/upDataRole',
    data
  })
}
export const getAuthList = data => {
  return request({
    method: 'POST',
    url: '/api/getAuthList',
    data
  })
}
export const getSelectAuth = data => {
  return request({
    method: 'POST',
    url: '/api/getSelectAuth',
    data
  })
}
export const upSelectAuth = data => {
  return request({
    method: 'POST',
    url: '/api/upSelectAuth',
    data
  })
}
// 栏目
export const getNavList = data => {
  return request({
    method: 'POST',
    url: '/api/getNavList',
    data
  })
}
// 文章
export const getArticleList = data => {
  return request({
    method: 'POST',
    url: '/api/getArticleList',
    data
  })
}
export const addArticle = data => {
  return request({
    method: 'POST',
    url: '/api/addArticle',
    data
  })
}
export const editArticle = data => {
  return request({
    method: 'POST',
    url: '/api/editArticle',
    data
  })
}
export const updateArticle = data => {
  return request({
    method: 'POST',
    url: '/api/updateArticle',
    data
  })
}
export const delArticle = data => {
  return request({
    method: 'POST',
    url: '/api/delArticle',
    data
  })
}
export const stateArticle = data => {
  return request({
    method: 'POST',
    url: '/api/stateArticle',
    data
  })
}
export const getWxArticle = data => {
  return request({
    method: 'POST',
    url: '/api/getWxArticle',
    data
  })
}
export const getClassifyList = data => {
  return request({
    method: 'POST',
    url: '/api/getClassifyList',
    data
  })
}
export const getClassify = data => {
  return request({
    method: 'POST',
    url: '/api/getClassify',
    data
  })
}
export const addClassify = data => {
  return request({
    method: 'POST',
    url: '/api/addClassify',
    data
  })
}
export const editClassify = data => {
  return request({
    method: 'POST',
    url: '/api/editClassify',
    data
  })
}
export const updataClassify = data => {
  return request({
    method: 'POST',
    url: '/api/updataClassify',
    data
  })
}
export const delClassify = data => {
  return request({
    method: 'POST',
    url: '/api/delClassify',
    data
  })
}
export const stateClassify = data => {
  return request({
    method: 'POST',
    url: '/api/stateClassify',
    data
  })
}
export const getSynchro = data => {
  return request({
    method: 'POST',
    url: '/api/getSynchro',
    data
  })
}
export const getAdList = data => {
  return request({
    method: 'POST',
    url: '/api/getAdList',
    data
  })
}
export const addAd = data => {
  return request({
    method: 'POST',
    url: '/api/addAd',
    data
  })
}
export const getEditAd = data => {
  return request({
    method: 'POST',
    url: '/api/getEditAd',
    data
  })
}
export const upDataAd = data => {
  return request({
    method: 'POST',
    url: '/api/upDataAd',
    data
  })
}
export const stateAd = data => {
  return request({
    method: 'POST',
    url: '/api/stateAd',
    data
  })
}
export const getDelAd = data => {
  return request({
    method: 'POST',
    url: '/api/getDelAd',
    data
  })
}
export const getSetup = data => {
  return request({
    method: 'POST',
    url: '/api/getSetup',
    data
  })
}
export const upDataSetup = data => {
  return request({
    method: 'POST',
    url: '/api/upDataSetup',
    data
  })
}
export const setClass = data => {
  return request({
    method: 'POST',
    url: '/api/setClass',
    data
  })
}
export const setAd = data => {
  return request({
    method: 'POST',
    url: '/api/setAd',
    data
  })
}
export const getNav = data => {
  return request({
    method: 'POST',
    url: '/api/getNav',
    data
  })
}
export const addNav = data => {
  return request({
    method: 'POST',
    url: '/api/addNav',
    data
  })
}
export const editNav = data => {
  return request({
    method: 'POST',
    url: '/api/editNav',
    data
  })
}
export const updateNav = data => {
  return request({
    method: 'POST',
    url: '/api/updateNav',
    data
  })
}
export const delNav = data => {
  return request({
    method: 'POST',
    url: '/api/delNav',
    data
  })
}
export const stateNav = data => {
  return request({
    method: 'POST',
    url: '/api/stateNav',
    data
  })
}
export const getNavLists = data => {
  return request({
    method: 'POST',
    url: '/api/getNavLists',
    data
  })
}
export const getVideo = data => {
  return request({
    method: 'POST',
    url: '/api/getVideo',
    data
  })
}
export const addVideo = data => {
  return request({
    method: 'POST',
    url: '/api/addVideo',
    data
  })
}
export const editVideo = data => {
  return request({
    method: 'POST',
    url: '/api/editVideo',
    data
  })
}
export const updateVideo = data => {
  return request({
    method: 'POST',
    url: '/api/updateVideo',
    data
  })
}
export const delVideo = data => {
  return request({
    method: 'POST',
    url: '/api/delVideo',
    data
  })
}
export const stateVideo = data => {
  return request({
    method: 'POST',
    url: '/api/stateVideo',
    data
  })
}
export const upFileVideo = data => {
  return request({
    method: 'POST',
    url: '/api/upFileVideo',
    data
  })
}
export const upFile = data => {
  return request({
    method: 'POST',
    url: '/api/upFile',
    data
  })
}
export const searchArticle = data => {
  return request({
    method: 'POST',
    url: '/api/searchArticle',
    data
  })
}
export const stateVideoRecommend = data => {
  return request({
    method: 'POST',
    url: '/api/stateVideoRecommend',
    data
  })
}
export const getTypeList = data => {
  return request({
    method: 'POST',
    url: '/api/getTypeList',
    data
  })
}
export const videoUpFile = data => {
  return request({
    method: 'POST',
    url: '/api/upFiles?action=merge',
    data
  })
}
export const upImg = data => {
  return request({
    method: 'POST',
    url: '/api/upImg',
    data
  })
}
export const getClassVideo = data => {
  return request({
    method: 'POST',
    url: '/api/getClassVideo',
    data
  })
}
export const editClassVideo = data => {
  return request({
    method: 'POST',
    url: '/api/editClassVideo',
    data
  })
}
export const updateClassVideo = data => {
  return request({
    method: 'POST',
    url: '/api/updateClassVideo',
    data
  })
}
export const stateClassvideo = data => {
  return request({
    method: 'POST',
    url: '/api/stateClassvideo',
    data
  })
}
