<template>
  <div>
    <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24">
      <el-card shadow="never">
        <el-form label-width="120px">
          <el-form-item label="搜索："
                        class="el-form-items">
            <el-input placeholder="请输入内容"
                      v-model="searchname"
                      clearable
                      class="input-with-select"
                      style="margin-top:5px">
              <el-button slot="append"
                         icon="el-icon-search"
                         :loading="updateLoading"
                         @click="search"></el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <div style="float:right;">
          <el-button type="primary"
                     icon="el-icon-plus"
                     size="mini"
                     style="margin: 0 10px 10px 0;"
                     @click="addad">添加</el-button>
        </div>
        <el-table :data="videolistdata"
                  style="width: 100%"
                  row-key="id"
                  border
                  lazy
                  :header-cell-style="{background:'#e8eaec',color:'#606266'}">
          <el-table-column label="序号"
                           type="index"
                           align="center"
                           width="80">
          </el-table-column>
          <el-table-column prop="title"
                           label="标题">
          </el-table-column>
          <el-table-column prop="pic"
                           label="缩略图">
            <template slot-scope="scope">
              <div class="tabBox_img"
                   v-if="scope.row.pic!==''"><img :src="scope.row.pic"></div>
            </template>
          </el-table-column>
          <el-table-column prop="cate_name"
                           label="分类">
          </el-table-column>
          <el-table-column prop="author"
                           label="作者">
          </el-table-column>
          <el-table-column prop="sort"
                           label="排序">
          </el-table-column>
          <el-table-column prop="recommend"
                           label="是否广告">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.recommend"
                         :active-value="1"
                         :inactive-value="2"
                         @change="onisshows(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="status"
                           label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status"
                         :active-value="1"
                         :inactive-value="2"
                         @change="onisshow(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center"
                           width="250">
            <template slot-scope="scope">
              <el-button size="mini"
                         @click="handleEdit(scope.row)"
                         icon="el-icon-edit">修改</el-button>
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.row)"
                         icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;"
             v-show="total!=0">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-sizes="[10,20,30, 50, 100]"
                         :page-size="10"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </el-card>
    </el-col>
    <el-dialog :title="title"
               append-to-body
               :close-on-click-modal="false"
               :visible.sync="dialogaddad">
      <el-form ref="ad-form"
               :model="form"
               label-width="150px"
               :rules="formRule">
        <el-form-item label="分类"
                      prop="cateid">
          <el-select v-model="form.cateid"
                     placeholder="请选择分类">
            <el-option-group v-for="item in classifydata"
                             :key="item.id"
                             :label="item.name">
              <el-option v-for="items in item.catelist"
                         :key="items.id"
                         :label="items.cate_name"
                         :value="items.id">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="视频标题"
                      prop="title"
                      placeholder="请输入视频标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="上传视频">
          <uploader :options="options"
                    @file-success="onFileSuccess"
                    :fileStatusText="fileStatusText" class="uploader">
            <uploader-unsupport></uploader-unsupport>
            <uploader-drop>
              <uploader-btn :attrs="attrs"
                            style="background-color: #67C13B"><i class="el-icon-upload"
                   style="margin-right: 5px"></i>上传视频</uploader-btn>
            </uploader-drop>
            <uploader-list></uploader-list>
          </uploader>
        </el-form-item>
        <el-form-item label="缩略图(640*360)">
          <el-upload class="avatar-uploader"
                     action="https://mpapi.ghtechwx.com/api/upImg"
                     :headers="myHeaders"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl"
                 :src="imageUrl"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="作者"
                      prop="author"
                      placeholder="请输入作者">
          <el-input v-model="form.author"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="form.des">
          </el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.sort"
                    style="width:230px"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    placeholder="数值越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="2">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSubmit(type)">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { getVideo, addVideo, editVideo, updateVideo, delVideo, stateVideo, getClassify, stateVideoRecommend, videoUpFile } from '@/api/global'
export default {
  name: 'AdIndex',
  data () {
    return {
      dialogaddad: false,
      imageUrl: '',
      dialogImg: false,
      updateLoading: false,
      controllerErrorFileDialog: false,
      title: '',
      type: 1,
      myHeaders: '',
      currentPage: 1,
      total: 0,
      perpage: 10,
      form: {
        cateid: '',
        title: '',
        author: '',
        url: '',
        sort: 0,
        pic: '',
        des: '',
        status: 1
      },
      searchname: '',
      addata: [],
      formRule: {
        cateid: [{ required: true, message: '请选择分类', trigger: 'change' }],
        title: [{ required: true, message: '请输入视频标题', trigger: 'blur' }]
      },
      videolistdata: [],
      scanning: [],
      classifydata: [],
      options: {
        target: 'https://mpapi.ghtechwx.com/api/upFiles?action=Upload',
        testChunks: false,
        singleFile: true,
        chunkSize: 1024 * 1024 * 3, // 2MB
        simultaneousUploads: 1, // 并发上传数
        headers: {
          Authorization: (JSON.parse(window.localStorage.getItem('user'))).token
        },
        maxChunkRetries: 1, // 最大自动失败重试上传次数
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) { // 格式化时间
          return parsedTimeRemaining
            .replace(/\syears?/, '年')
            .replace(/\days?/, '天')
            .replace(/\shours?/, '小时')
            .replace(/\sminutes?/, '分钟')
            .replace(/\sseconds?/, '秒')
        }
      },
      fileStatusText: {
        success: '',
        error: '上传失败',
        uploading: '上传中...',
        paused: '暂停',
        waiting: '等待中...'
      },
      attrs: {
        accept: ['video/mp4']
      }
    }
  },
  created () {
    this.loadtoken()
    this.loadgetClassify()
    this.loadgetAdList()
  },
  mounted () {
  },
  methods: {
    loadtoken () {
      const user = JSON.parse(window.localStorage.getItem('user'))
      this.myHeaders = { Authorization: user.token }
    },
    loadgetClassify () {
      getClassify({ type: 2 }).then(res => {
        if (res.data.status === 'success') {
          this.classifydata = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetAdList () {
      getVideo({ searchname: this.searchname, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.videolistdata = res.data.data.page_items
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    search () {
      this.loading = true
      this.updateLoading = true
      getVideo({ searchname: this.searchname, page: this.currentPage, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.videolistdata = res.data.data.page_items
          this.loading = false
          this.updateLoading = false
          this.currentPage = res.data.page
          this.total = res.data.total
        } else {
          this.updateLoading = false
        }
      }).catch(err => {
        this.updateLoading = false
        console.log('保存失败', err)
      })
    },
    handleSizeChange (val) {
      this.perpage = val
      this.loading = true
      getVideo({ searchname: this.searchname, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.loading = false
          this.currentPage = res.data.data.page
          this.total = res.data.data.total
          this.videolistdata = res.data.data.page_items
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    handleCurrentChange (val) {
      this.loading = true
      getVideo({ searchname: this.searchname, page: val, per: this.perpage }).then(res => {
        if (res.data.status === 'success') {
          this.loading = false
          this.currentPage = res.data.data.page
          this.total = res.data.data.total
          this.videolistdata = res.data.data.page_items
        }
      }).catch(err => {
        this.loading = false
        console.log('保存失败', err)
      })
    },
    addad () {
      this.dialogaddad = true
      this.title = '视频添加'
      this.type = 1
    },
    onSubmit (type) {
      // 表单验证
      // 验证通过，提交表单
      this.$refs['ad-form'].validate(valid => {
        if (!valid) {
          return false
        }
        if (type === 1) {
          this.addAd()
        } else {
          this.upDataAd()
        }
      })
    },
    addAd () {
      addVideo({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogaddad = false
          this.form.title = ''
          this.form.url = ''
          this.form.author = ''
          this.form.pic = ''
          this.imageUrl = ''
          this.loadgetAdList()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onisshow (res) {
      stateVideo({ id: res.id, status: res.status }).then(res => {
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onisshows (res) {
      console.log(res)
      stateVideoRecommend({ id: res.id, recommend: res.recommend }).then(res => {
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    handleEdit (row) {
      this.dialogaddad = true
      this.title = '视频修改'
      this.type = 2
      editVideo({ id: row.id }).then(res => {
        this.form = res.data.data
        this.imageUrl = res.data.data.pic
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    upDataAd () {
      updateVideo({ data: this.form }).then(res => {
        if (res.data.status === 'success') {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.dialogaddad = false
          this.loadgetAdList()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log('修改失败', err)
      })
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delVideo({ id: row.id }).then(res => {
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.loadgetAdList()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log('删除失败', err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleAvatarSuccess (res, file) {
      if (res.status === 'success') {
        this.form.pic = res.data.url
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    scanningsuccess (res) {
      if (res.status === 'success') {
        this.scanning = this.scanning.concat(res.data)
        this.form.url = res.data.url
      } else {
        this.$message.warning('文件上传失败')
      }
    },
    scanningRemove (file) {
      this.scanning.some((item, i) => {
        if (item.url === file.url) {
          this.scanning.splice(i, 1)
          return true
        }
      })
    },
    beforeUpload (file) {
      /* var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'pdf'
      if (!extension) {
        this.$message({
          message: '上传文件只能是pdf格式!',
          type: 'warning'
        })
        return false
      }
      return extension */
    },
    onFileSuccess (rootFile, file, response, chunk) {
      const resp = JSON.parse(response)
      // 合并分片
      if (resp.data.vurl === null) {
        console.log(11)
        videoUpFile({
          filename: file.name,
          identifier: file.uniqueIdentifier,
          totalSize: file.size,
          totalChunks: chunk.offset
        }).then(res => {
          if (res.data.data.code === 0) {
            this.form.url = res.data.data.vurl
            this.fileStatusText.success = '上传成功'
          } else {
            this.$message.error('上传失败')
            this.fileStatusText.success = '上传失败'
            this.fileStatusText.error = '上传失败'
          }
        }).catch(err => {
          this.fileStatusText.error = '上传失败'
          console.log('上传失败', err)
        })
      } else {
        this.fileStatusText.success = '上传成功'
        this.form.url = resp.data.vurl
      }
    }

  }
}
</script>
<style lang="less" scoped>
/deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}
.tabBox_img img {
  width: 100%;
  height: 100%;
}
/deep/ .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 0px solid #ebeef5;
}
.el-form-items {
  float: left;
}
// 文件上传组件
.uploader {
    padding: 15px;
    font-size: 14px;
    .uploader-btn {
        margin-right: 4px;
        color: #fff;
        padding: 6px 16px;
    }
    .upfile{
        border: 1px solid #409eff;
        background: #409eff;
    }
    .updir{
        border: 1px solid #67c23a;
        background: #67c23a;
    }
    .uploader-list {
        max-height: 440px;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
    // /deep/.uploader-drop{
    //     height: 400px;
    // }
}
//手机等小屏幕手持设备。当设备宽度  在  320px和768px之间时,执行当前的css
@media only screen and (min-width: 320px) and (max-width: 768px) {
    .uploader {
        width: 98%;
        padding: 0;
        box-shadow: none;
    }
}
</style>
